import * as React from 'react'

// Types
import { IconRotate, IconSize } from '@/lib/enums'

const Arrow: React.FC<IconProps> = ({
  color = 'currentColor',
  direction = IconRotate.South,
  size = IconSize.Large,
  className
}) => {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      style={{ transform: `rotate(${direction})` }}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M15.5355 18.8787L20.4853 13.9289L19.0711 12.5147L14.1213 17.4645C13.7938 17.792 13.4094 18.0279 13 18.1723L13 3L11 3L11 18.1723C10.5905 18.0279 10.2062 17.792 9.87867 17.4645L4.92892 12.5147L3.51471 13.929L8.46445 18.8787C10.4171 20.8313 13.5829 20.8313 15.5355 18.8787Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default React.memo(Arrow)
